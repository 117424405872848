// @ts-nocheck
import React from 'react';
import { Link } from 'react-router-dom';
import styles from './common.module.css';
import logo from './../assets/devdoctorslogo2.svg'

const Navigation = () => {
    return (
        <div className={`${styles.nav_container_head}`}>
            <div className={`${styles.nav_container}`}>
                <div className={`${styles.nav_box}`}>
                    <h1>
                        <Link to="/">
                            <img src={logo} alt="DevDoctor's" width="150" 
                            />
                        </Link>
                    </h1>
                    <ul>
                        <li>
                            <Link>Home</Link>
                        </li>
                        <li>
                            <Link to="/contactus">Contact Us</Link>
                        </li>
                        <li>
                            <Link>Help</Link>
                        </li>
                    </ul>
                </div>
                <div className={`${styles.sign_box}`}>
                    <button type="button" className={`${styles.login_button}`}>
                        <Link to="/login">Login</Link>
                    </button>
                    <button type="button" className={`${styles.register_button}`}>
                        <Link to="/register">Register</Link>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Navigation;
