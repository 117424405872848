import React from 'react';
import Navigation from './../../common/Navigation';
import Footer from '../../common/Footer';
import { services } from './servicesData';

const GettingStarted = () => {
    const [content, setContent] = React.useState('');
    const [servicesList, setServicesList] = React.useState(services);
    const [classNameData, setClassNameData] = React.useState('services-item');

    return (
        <div className="home-container">
            <Navigation />
            <>
                <h1>Getting Started</h1>
                <p>DevDoctor: A Guide to Landing Your Dream Job</p>
                <p></p>
                <div className="home-button-box">
                    <input
                        type="search"
                        id="search"
                        value={content}
                        onChange={(e) => {
                            setContent(e.target.value);
                            setServicesList(
                                services.filter((service) =>
                                    service.header
                                        .toLowerCase()
                                        .includes(e.target.value.toLowerCase()),
                                ),
                            );
                            setClassNameData(
                                services.filter((service) =>
                                    service.header
                                        .toLowerCase()
                                        .includes(e.target.value.toLowerCase()),
                                ).length > 0
                                    ? 'services-item'
                                    : 'services-item-hidden',
                            );
                        }}
                        placeholder="Search"
                    />

                    <button type="button" className="button-view">
                        Filter
                    </button>
                </div>

                <ul className="services-container">
                    {servicesList.length > 0 ? (
                        servicesList.map((service) => (
                            <li key={service.id} className={classNameData}>
                                <h2 className="services-item-header">{service.header}</h2>
                                <p className="services-item-title">{service.title}</p>
                                <button type="button" className="services-item-button">
                                    View More
                                </button>
                            </li>
                        ))
                    ) : (
                        <li className="services-item-hidden">
                            <h2 className="services-item-header not-found">
                                No Results Found
                            </h2>
                        </li>
                    )}
                </ul>
            </>
            <Footer />
        </div>
    );
};

export default GettingStarted;
