const routingMethod = {
    previousStep: (paramsMethod) => {
        paramsMethod(-1);
    },

    nextStep: (paramsMethod) => {
        paramsMethod(+1);
    },

    specialUrl: (paramsUrl, paramsMethod) => {
        paramsMethod(`/${paramsUrl}`);
    },
};

export default routingMethod;
