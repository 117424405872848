import { createSlice } from '@reduxjs/toolkit';



const initialState = {
    isAuth: false,
    user: null,
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        loginProvider: (state, action) => {
            state.isAuth = true;
            state.user = action.payload;
        }

    }
});



export const selectedUser = (state) => {
  return  state.auth.isAuth ? state.user : null;
}

export const { loginProvider } = authSlice.actions;


export default authSlice.reducer;