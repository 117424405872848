// @ts-nocheck
import React from 'react';
import Dashboard from './Dashboard';



const App = () => {

    return (
        <>
            <Dashboard />
        </>

    );
}

export default App;
