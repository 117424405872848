// Path: client\src\app\store.js

import { configureStore } from '@reduxjs/toolkit';
import { debuggerApi } from './debuggerApi';
import { setupListeners } from '@reduxjs/toolkit/query';
import authReducer from './../features/redux/authSlice'


export const store = configureStore({
    reducer: {
        // Adding the api reducer sets up listeners and other
        // necessary wiring for `rtk-query`

        auth: authReducer,
        [debuggerApi.reducerPath]: debuggerApi.reducer
    },
    // Adding the api middleware enables caching, invalidation, polling,
    // and other useful features of `rtk-query`.
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(debuggerApi.middleware),
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);
