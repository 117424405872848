// @ts-nocheck
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './app/store';
import App from './root/App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';


const root = createRoot(document.getElementById('root'));

root.render(
 
        <Provider store={store}>
            <BrowserRouter>

                <App />

            </BrowserRouter>
        </Provider>
);


reportWebVitals();

// to register() or unregister() your service worker.
serviceWorkerRegistration.register();
