// @ts-nocheck
import React, { useState, useEffect } from 'react';
import ProductCard from './ProductCard';
import { services } from './../GettingStarted/servicesData';
import Footer from '../../common/Footer';
import { selectedUser } from './../../redux/authSlice';
import { useSelector } from 'react-redux';
import logo from './../../assets/devdoctorslogo2.svg';

const MarketPlace = () => {
    const [products, setProducts] = useState(services);
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [sortOption, setSortOption] = useState('priceAsc');
    const [currentPage, setCurrentPage] = useState(1);
    const [productsPerPage, setProductsPerPage] = useState(6);
    const [cart, setCart] = useState([]);
    const [clicked, setClicked] = useState(false);
    const user = useSelector(selectedUser);

    const [userData, setUserData] = useState(user || null);

    const filteredProducts = products.filter((product) =>
        product.title.toLowerCase().includes(searchTerm.toLowerCase()),
    );

    const sortedProducts = [...filteredProducts].sort((a, b) => {
        switch (sortOption) {
            case 'priceAsc':
                return a.price - b.price;
            case 'priceDesc':
                return b.price - a.price;
            case 'ratingAsc':
                return a.rating - b.rating;
            case 'ratingDesc':
                return b.rating - a.rating;
            default:
                return 0;
        }
    });

    const indexOfLastProduct = currentPage * productsPerPage;
    const indexOfFirstProduct = indexOfLastProduct - productsPerPage;

    const currentProducts = sortedProducts.slice(indexOfFirstProduct, indexOfLastProduct);

    const totalPages = Math.ceil(sortedProducts.length / productsPerPage);
    const pageNumbers = [];

    for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
    }

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
        setCurrentPage(1);
    };

    const handleSortChange = (event) => {
        setSortOption(event.target.value);
        setCurrentPage(1);
    };

    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
            setProducts(services);
            setProductsPerPage(6);
        }, 1000);

        if (user) {
            setUserData(user);
        }
    }, []);

    return (
        <>
            <div className={`${clicked ? 'modal-user-login' : 'modal-user-login-none'}`}>
                <button
                    type="button"
                    className={`close-modal-user-login`}
                    onClick={() => {
                        setClicked((pre) => (pre ? false : false));
                    }}
                >
                    X
                </button>
            </div>
            <div
                className="home-marketplace-container"
                style={{
                    opacity: clicked ? '0.2' : '1',
                }}
                onClick={() => {
                    if (clicked) {
                        setClicked(false);
                    }
                }}
            >
                <div className="side-menu">
                    <img
                        src={logo}
                        alt="DevDoctor's"
                        width="250"
                    />

                    {userData && userData ? (
                        <div className="user-info">
                            <div className="user-image">
                                <img src={userData.avatar} alt="user" />
                            </div>
                            <div className="user-name">
                                <h1>{userData.name}</h1>
                            </div>
                        </div>
                    ) : (
                        <div className="user-info">
                            <div className="user-image">
                                <img src="https://i.imgur.com/6VBx3io.png" alt="user" />
                            </div>
                            <div className="user-name">
                                <h1>Guest</h1>
                            </div>
                            {/* gusest user cta for register */}
                            <div className="user-cta">
                                <p>
                                    You are currently logged in as a guest. Please register to
                                    continue. and enjoy our services.
                                </p>
                                <button

                                    type="button"
                                    onClick={() => {
                                        setClicked((pre) => (pre ? false : true));
                                    }}
                                >
                                    Register
                                </button>


                            </div>
                        </div>
                    )}
                </div>
                <div className="main-marketplace-content">
                    <div className="marketplace-container">
                        <div className="marketplace-header">
                            <h1>Marketplace</h1>
                            <div className="marketplace-controls">
                                <div className="search-control">
                                    <input
                                        type="text"
                                        placeholder="Search"
                                        value={searchTerm}
                                        onChange={handleSearchChange}
                                    />
                                </div>
                                <div className="sort-control">
                                    <label htmlFor="sort-selected">Sort By:</label>
                                    <select
                                        name="sort-select"
                                        id="sort-select"
                                        onChange={handleSortChange}
                                        defaultValue={sortOption}
                                    >
                                        <option id="sort-selected">
                                            Price: Low to High
                                        </option>
                                        <option value="priceDesc">
                                            Price: High to Low
                                        </option>
                                        <option value="ratingAsc">
                                            Rating: Low to High
                                        </option>
                                        <option value="ratingDesc">
                                            Rating: High to Low
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="product-list">
                            {loading ? (
                                <p>Loading...</p>
                            ) : (
                                <>
                                    {currentProducts.map((product) => (
                                        <ProductCard
                                            key={product.id}
                                            product={product}
                                            relatedProducts={products
                                                .filter(
                                                    (p) =>
                                                        p.category === product.category &&
                                                        p.id !== product.id,
                                                )
                                                .slice(0, 3)}
                                            cart={cart}
                                            setCart={setCart}
                                            userData={userData}
                                            setClicked={setClicked}
                                        />
                                    ))}
                                </>
                            )}
                        </div>
                        <div
                            className="
                pagination-container
      "
                        >
                            <ul className="pagination">
                                {pageNumbers.map((pageNumber) => (
                                    <li
                                        key={pageNumber}
                                        className={
                                            currentPage === pageNumber ? 'active' : ''
                                        }
                                        onClick={() => handlePageClick(pageNumber)}
                                    >
                                        {pageNumber}
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>
        </>
    );
};

export default MarketPlace;
