// @ts-nocheck
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query';

export const debuggerApi = createApi({
    reducerPath: 'debuggerApi',
    baseQuery: fetchBaseQuery({
        baseUrl:
            'mongodb+srv://devdoctoradmin:adminpassword123!@cluster0.wroodfy.mongodb.net/?retryWrites=true&w=majority',
    }),
    endpoints: () => ({}),
    tagTypes: ['Users', 'User', 'Services', 'Orders'],
    entityTypes: ['Users', 'User', 'Services', 'Orders']
});
